<template>
  <div>
    <LoansCalculator
      :value="{}"
      :member_to_loan="{}"
      :can_edit="true"
    ></LoansCalculator>
  </div>
</template>
<script>
  import LoansCalculator from '@/views/create_interface/LoansData'

  export default {
    components: {
      LoansCalculator,
    },
  }
</script>
